export const deliveryDateHelper = () => {
  const isInvalidDeliveryDate = (subjectDate, fromDate, toDate) => {
    let from = new Date();
    let to = new Date();

    if (subjectDate) {
      subjectDate.setHours(0, 0, 0, 0);
    }

    if (fromDate) {
      from = new Date(fromDate);
      from.setHours(0, 0, 0, 0);
    }

    if (toDate) {
      to = new Date(toDate);
      to.setHours(0, 0, 0, 0);
    }

    return subjectDate < from || subjectDate > to;
  };

  return {
    isInvalidDeliveryDate
  };
};
