import { computed } from '@nuxtjs/composition-api';

import { useConfig } from '~/composables';
import type { Optin } from '~/models';
import { OptinTypes } from '~/models';

export const useOptins = () => {
  const { config } = useConfig();
  const areOptinsCheckedByDefault = computed(() => !!config?.value?.is_subscribed);

  const optinsLabelMap = {
    [OptinTypes.NEWSLETTER]: 'By Email',
    [OptinTypes.SMS]: 'By SMS and Phone',
    [OptinTypes.MAIL]: 'By Postal Mail'
  };

  const optins = computed<Optin[]>(() => {
    const optinsString = config.value?.optins?.trim() || '';

    return (
      (optinsString.length &&
        optinsString
          .split(' ')
          .filter((optin: string): Optin => optinsLabelMap[optin])
          .map(
            (optin: string): Optin => ({
              id: optin,
              label: optinsLabelMap[optin]
            })
          )) ||
      []
    );
  });

  return {
    optins,
    areOptinsCheckedByDefault
  };
};
