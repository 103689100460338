import { ref } from '@nuxtjs/composition-api';

export const useGoogleAutocomplete = () => {
  const isLoaded = ref<boolean>(false);
  const isLoading = ref<boolean>(false);
  let observer: MutationObserver | null = null;

  const loadGoogleMaps = (apiKey: string): Promise<void> => {
    // If script is already being loaded or has loaded - don't load again
    if (isLoading.value || isLoaded.value || window.google?.maps) {
      return Promise.resolve();
    }

    isLoading.value = true;

    return new Promise<void>((resolve, reject) => {
      // Check if script is already in the DOM
      const existingScript = document.querySelector('script[src*="maps.googleapis.com/maps/api/js"]');

      if (existingScript) {
        isLoaded.value = true;
        isLoading.value = false;
        resolve();
        return;
      }

      // Set callback for script loading
      window.initMapsCallback = () => {
        isLoaded.value = true;
        isLoading.value = false;
        resolve();
      };

      // Create and append script
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMapsCallback`;
      script.async = true;
      script.defer = true;
      script.onerror = (error) => {
        isLoading.value = false;
        reject(error);
      };

      document.head.appendChild(script);
    });
  };

  const stopClickPropagation = (event) => {
    event.stopPropagation();
  };

  const attachGoogleDropdownListener = () => {
    const googleDropdowns = document.querySelectorAll('.pac-container');

    if (googleDropdowns.length > 0) {
      googleDropdowns.forEach((dropdown) => {
        // Capture phase to ensure these handlers run first
        dropdown.removeEventListener('mousedown', stopClickPropagation, true);
        dropdown.addEventListener('mousedown', stopClickPropagation, true);

        // Also prevent touchstart events for mobile
        dropdown.removeEventListener('touchstart', stopClickPropagation, true);
        dropdown.addEventListener('touchstart', stopClickPropagation, true);
      });
    }
  };

  const setupObserver = () => {
    if (observer) {
      observer.disconnect();
    }

    observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.addedNodes.length) {
          const hasPacContainer = Array.from(mutation.addedNodes).some((node) => {
            if (node.nodeType === Node.ELEMENT_NODE) {
              const element = node as Element;

              if (element.classList?.contains('pac-container')) return true;

              return element.querySelector?.('.pac-container') !== null;
            }
            return false;
          });

          if (hasPacContainer) {
            attachGoogleDropdownListener();
          }
        }
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: false
    });
  };

  const resetObserver = () => {
    observer.disconnect();
    observer = null;
  };

  return {
    observer,
    isLoaded,
    isLoading,
    setupObserver,
    resetObserver,
    loadGoogleMaps,
    attachGoogleDropdownListener
  };
};
