import gql from 'graphql-tag';

/**
 * GraphQL Query that fetches countries list
 */
export default gql`
  query countries {
    countries {
      id
      two_letter_abbreviation
      three_letter_abbreviation
      full_name_locale
      full_name_english
      available_regions {
        id
        code
        name
      }
    }
  }
`;
