import { computed, useContext } from '@nuxtjs/composition-api';

export function useStoreLocale() {
  const { i18n } = useContext();
  const storeCode = computed(() => i18n.localeProperties.code);

  const isDeStore = computed(() => storeCode.value === 'de_eu');
  const isZhHkStore = computed(() => ['zh_hk', 'en_hk'].includes(storeCode.value));
  const isJpStore = computed(() => storeCode.value === 'ja_jp');
  const isUsCaStore = computed(() => ['en_us', 'fr_us'].includes(storeCode.value));

  return {
    storeCode,
    isDeStore,
    isZhHkStore,
    isJpStore,
    isUsCaStore
  };
}
