import { useCartStore, useUserStore } from '@checkout/stores';
import { computed, useContext } from '@nuxtjs/composition-api';

import { useApi, useCart, useUiNotification, useUser } from '~/composables';
import { lsSet } from '~/diptyqueTheme/composable/useLocalStorage';
import userToken from '~/diptyqueTheme/customQueries/magento/userToken';
import { Logger } from '~/helpers/logger';
import { useCustomerStore } from '~/modules/customer/stores/customer';

import type { FBStatusResponse, GoogleButtonText, loginType, userTokenResponseInterface, useSocialLoginInterface } from './types';

export function useSocialLogin(): useSocialLoginInterface {
  const { app } = useContext();
  const { query } = useApi();
  const apiState = app.context.$vsf.$magento.config.state;
  const { setCart } = useCart();
  const { load: loadUser, setToken } = useUser();
  const { send: sendNotification } = useUiNotification();
  const isFbSocialLoginEnabled = computed(() => Boolean(process.env.VSF_FB_CLIENT_ID));
  const isGoogleSocialLoginEnabled = computed(() => Boolean(process.env.VSF_GOOGLE_CLIENT_ID));
  const customerStore = useCustomerStore();

  const userStore = useUserStore();
  const cartStore = useCartStore();

  const initFacebookLogin = () => {
    try {
      window.FB.init({
        appId: process.env.VSF_FB_CLIENT_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v16.0'
      });
      Logger.info('[SOCIAL] Facebook SDK initialized');
    } catch (e) {
      Logger.error('[SOCIAL] Failed to initialize Facebook SDK', e);
    }
  };

  const loginWithFacebook = () => {
    try {
      window.FB.login(
        (response: FBStatusResponse) => {
          if (response.status === 'connected' && response?.authResponse) {
            const accessToken = response.authResponse.accessToken;
            authCallback(accessToken, 'facebook');
          } else {
            const translatedMessage = app.i18n.t(
              'We are not able to sign you in with Facebook. Please verify your social settings or switch to another method.'
            );

            // todo @i.sprut: move it to separate function;
            sendNotification({
              icon: 'error',
              id: Symbol(`facebook-social_login_error`),
              message: String(translatedMessage),
              persist: false,
              title: 'Social login error',
              type: 'danger',
              area: 'top'
            });
          }
        },
        { scope: 'public_profile,email' }
      );
      Logger.info('[SOCIAL] Facebook login initiated');
    } catch (e) {
      Logger.error('[SOCIAL] Failed to initiate Facebook login', e);
    }
  };

  const initGoogleLogin = () => {
    try {
      window?.google?.accounts.id.initialize({
        client_id: process.env.VSF_GOOGLE_CLIENT_ID,
        callback: (response) => {
          const token = response.credential;
          authCallback(token, 'google');
        },
        context: 'signin',
        use_fedcm_for_prompt: true
      });
      Logger.info('[SOCIAL] Google login initialized');
    } catch (e) {
      Logger.error('[SOCIAL] Failed to initialize Google login', e);
    }
  };

  const renderGoogleButton = (parent: HTMLElement = undefined, text: GoogleButtonText = 'continue_with') => {
    try {
      window?.google?.accounts.id.renderButton(parent, {
        type: 'icon',
        theme: 'outline',
        shape: 'circle',
        size: 'medium',
        text
      });
      Logger.info('[SOCIAL] Google button rendered');
    } catch (e) {
      Logger.error('[SOCIAL] Google library isn`t ready', e);
    }
  };

  const authCallback = async (token: string, strategy: loginType) => {
    setLocalStorageAuthStrategy(strategy);
    await getTokenAndSetUser(token, strategy);
    Logger.info(`[SOCIAL] ${strategy} callback executed`);
  };

  const setUserCart = async () => {
    const currentCartId = apiState.getCartId();
    const cart = await app.context.$vsf.$magento.api.customerCart();
    const newCartId = cart.data.customerCart.id;
    if (newCartId && currentCartId && currentCartId !== newCartId) {
      const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts({
        sourceCartId: currentCartId,
        destinationCartId: newCartId
      });
      setCart(dataMergeCart.mergeCarts);
      cartStore.setCart(dataMergeCart.mergeCarts);
      apiState.setCartId(dataMergeCart.mergeCarts.id);
    } else {
      setCart(cart.data.customerCart);
      cartStore.setCart(cart.data.customerCart);
    }
  };

  const getTokenAndSetUser = async (token: string, login_type: loginType) => {
    try {
      const { data, errors }: userTokenResponseInterface = await query(userToken, {
        token,
        login_type
      });
      if (errors?.length) {
        sendNotification({
          icon: 'error',
          id: Symbol(`${login_type}-social_login_error`),
          message: app.i18n.t('We are not able to sign you in with {login_type}. Please verify your social settings or switch to another method.', {
            login_type: login_type.charAt(0).toUpperCase() + login_type.slice(1)
          }) as string,
          persist: false,
          title: 'Social login error',
          type: 'danger',
          area: 'top'
        });
        return;
      }
      if (data?.getSocialLoginToken) {
        setToken(data.getSocialLoginToken);
        customerStore.setIsLoggedIn(true);
        customerStore.setIsTokenExpired(false);

        await loadUser({
          customQuery: {
            customer: 'customerWithOptions'
          }
        });
        await setUserCart();
        userStore.setLoginStatus(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const setLocalStorageAuthStrategy = (strategy) => {
    lsSet('strategy', strategy);
  };

  return {
    initGoogleLogin,
    initFacebookLogin,
    isFbSocialLoginEnabled,
    isGoogleSocialLoginEnabled,
    loginWithFacebook,
    renderGoogleButton
  };
}
