/*
 * Plugin for persistence and rehydration of pinia stores.
 * https://prazdevs.github.io/pinia-plugin-persistedstate-v2/
 * */

import piniaPluginPersistedState from 'pinia-plugin-persistedstate';

const persistedStatePlugin = ({ $pinia }) => {
  $pinia.use(piniaPluginPersistedState);
};

export default persistedStatePlugin;
