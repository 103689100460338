/** GraphQL Mutation to set delivery date to current cart */
export default `
  mutation setDeliveryDateOnShipping($input: DeliveryDateInput) {
    setDeliveryDateOnShipping(input: $input) {
      success
      message
      fromDate
      toDate
    }
  }
`;
