const scrollBehaviorPlugin = ({ app }) => {
  if (process.client && app.router) {
    app.router.options.scrollBehavior = (to) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (to.hash) {
            resolve({ selector: to.hash, behavior: 'smooth' });
            return;
          }

          resolve({ left: 0, top: 0, behavior: 'smooth' });
        }, 250);
      });
    };
  }
};

export default scrollBehaviorPlugin;
